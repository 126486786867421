import * as React from 'react';
import { TextBox } from '../components/elements/index';
import PageSeo from '../components/elements/Seo/PageSeo';
import pageUnavailable from '../copy/404';
import PageMeta from '../metadata/hcp';

const pageUnavailablePage = () => {
  return (
    <>
      <PageSeo pageMeta={PageMeta} />
      <TextBox textboxData={pageUnavailable} />
    </>
  )
}

export default pageUnavailablePage;
